<template>
  <div>
    <h1 class="title">Shop</h1>

    <Shop />
  </div>
</template>

<script>
  // - components
  // - mixins
  // - props
  // - data
  // - computed
  // - watch
  // - lifecycle
  // - methods
  // - template

  export default {};
</script>
