<template>
  <div class="columns is-multiline">
    <div
      v-for="item in items"
      :key="item.title"
      class="column is-6-tablet is-6-desktop is-4-widescreen is-4-fullhd"
    >
      <Item
        :title="item.title"
        :imageurl="item.imageurl"
        :price="item.price"
        :description="item.description"
        :link="item.link"
        :free="item.free"
      />
    </div>
  </div>
</template>

<script>
  // - components
  // - mixins
  // - props
  // - data
  // - computed
  // - watch
  // - lifecycle
  // - methods
  // - template

  import shop from '../../shop.json';

  export default {
    computed: {
      items() {
        return shop;
      },
    },
  };
</script>
